<template>
  <div>
    <Navbar />

    <ScrollPanel style="width: 100%;" :style="{ 'height': windowHeight + 'px' }">

      <div class="container" style="margin-top: 65px">
        <Card class="p-m-5" style="background-color: #f8f8f8; ">
          <template #title>
            <b>Talep Takip</b>
          </template>
          <template #content>
            <div class="p-grid">
              <div class="p-sm-12 p-md-8">
                <img alt="logo" :src="require(`../../../assets/logo/taleptakip1.png`)" class=""
                  style="width: 100%; max-width: 65rem;" />
              </div>

              <div class="p-sm-12 p-md-4">
                <Timeline :value="TalepDurum" :v-if="showtimeline">
                  <template #opposite="slotProps">
                    <small class="p-text-secondary">{{ slotProps.item.date }}</small>
                  </template>
                  <template #content="slotProps">
                    {{ slotProps.item.status }}
                  </template>
                </Timeline>
              </div>
              <divider />
              <div class="p-sm-12 p-md-12">
                <div class="p-grid">
                  <div class="p-col-6  p-d-flex p-jc-center">
                    <div class="p-grid">
                      <div class="p-col-12  p-d-flex p-jc-center">
                        <p>Bir sorununuz mu var? Bize ulaşın</p>
                      </div>
                      <div class="p-col-12  p-d-flex p-jc-center">
                        <router-link to="/WhatsappTalepTakip"><a aria-label="Chat on WhatsApp"><img
                              alt="Chat on WhatsApp" src="../../../assets/whatsapp/WhatsAppButtonGreenSmall.svg" />
                          </a></router-link>
                      </div>
                    </div>
                  </div>
                  <div class="p-col-6  p-d-flex p-jc-center">
                    <Button v-if="ShowODemeButton" label="Ödeme Yapmak için tıklayın" icon="pi pi-money-bill"
                      class="p-button-outlined p-button-danger p-button-sm" style="height: 70%;" @click="OdemeYap()" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>


      </div>
    </ScrollPanel>
  </div>
</template>

<script>
import Navbar from "../navbar.vue";
import GlobalServis from "../../../services/GlobalServis";
export default {
  data() {
    return {
      TakipNo: null,
      ShowODemeButton: false,
      showtimeline: false,
      TalepDurum: [],
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    },
    InitServis() {
      this.showtimeline = false;
      this._getTimeline();
    },
    OdemeYap() {
      let data = {
        Barkod: this.$route.query.TakipNo,
      };
      GlobalServis.GlobalServis("POST", "GetOdemeKodTalepTakip", data).then(
        (res) => {
          if (res.status == 200) {
            this.$router.push({ path: "/Odeme/OdemeSayfasi", query: { OdemeKod: res.data, } })
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "warn",
              summary: "Uyarı",
              detail: "Takip numaranız hatalıdır",
              life: 5000,
            });
          }
        }
      )
    },
    _getTimeline(){
      let data = {
        Barkod: this.$route.query.TakipNo,
      };
      GlobalServis.GlobalServis("POST", "GetTalepTakip", data).then(
        (res) => {
          if (res.status == 200) {
            this.TalepDurum = res.data;
            if (this.TalepDurum) {
              this.showtimeline = true;
            }
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].status == "Ödeme Bekleniyor") {
                  this.ShowODemeButton = true;
              }
              if (res.data[i].status == "Ödeme Yapıldı") { 
                this.ShowODemeButton = false;
              }
            }
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "warn",
              summary: "Uyarı",
              detail: "Takip numaranız hatalıdır",
              life: 5000,
            });
          }
        }
      )
    }
  },
  created() {
    this.InitServis();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  components: {
    Navbar
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.customized-timeline) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}
</style>